import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "arbeiten-sie-mit-uns"
    }}>{`Arbeiten Sie mit uns!`}</h1>
    <p>{`Um jederzeit auf die Wünsche und Aufträge unserer Kunden
reagieren zu können, suchen wir stets nach Verstärkung für
unseren Familienbetrieb. Neben der selbstverständlichen
fachlichen Kompetenz legen wir großen Wert auf ein freundliches
und höfliches Auftreten unseren Kunden gegenüber. Hier finden
Sie unsere aktuellen Stellenangebote. Wenn Sie der Meinung sind,
dass Sie unser Team auch in anderer Form als in den
ausgeschriebenen Positionen verstärken können, sind wir
neugierig auf Sie und freuen uns auf Ihre Bewerbungsunterlagen,
die Sie auch gern hier online über das Kontaktformular an uns
senden können.`}</p>
    <p><img alt="Stellencomic" src={require(".//images/uploads/stellen.svg")} /></p>
    <br />
    <br />
    <hr></hr>
    <h2 {...{
      "id": "kundendiensttechniker-mwd"
    }}>{`Kundendiensttechniker (m/w/d)`}</h2>
    <h4 {...{
      "id": "aufgaben"
    }}>{`Aufgaben`}</h4>
    <ul>
      <li parentName="ul">{`Wartung haustechnischer Anlagen`}</li>
      <li parentName="ul">{`Lösung spannender Aufgaben.`}</li>
    </ul>
    <h4 {...{
      "id": "gesuchtes-profilkompetenzen"
    }}>{`Gesuchtes Profil/Kompetenzen`}</h4>
    <ul>
      <li parentName="ul">{`Profi, oder einer der es werden möchte.`}</li>
      <li parentName="ul">{`Sollte Lust auf Technik haben.`}</li>
      <li parentName="ul">{`Sollte Spaß am Umgang mit Menschen haben.`}</li>
      <li parentName="ul">{`Sollte kommunikativ, teamfähig und kreativ sein.`}</li>
    </ul>
    <h4 {...{
      "id": "was-bietet-wir-bieten"
    }}>{`Was bietet wir bieten`}</h4>
    <ul>
      <li parentName="ul">{`regionaler Einsatz`}</li>
      <li parentName="ul">{`Firmenfahrzeuge mit Klima + Standheizung`}</li>
      <li parentName="ul">{`sehr gute Arbeitsvorbereitung`}</li>
      <li parentName="ul">{`sehr gute technische Ausstattung`}</li>
      <li parentName="ul">{`für Entlohnung/ Urlaub/ Arbeitszeit werden wir eine sehr gute Lösung finden`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      